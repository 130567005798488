<template>
    <div>
        <div class="text-center">
            <div class="w-80 mt-3 mx-auto" v-if="data.text_2">
                <p class="font-weight-normal">{{ data.text_2 }}</p>
            </div>
            <div class="w-80 my-4 mx-auto" style="height: 250px">
                <quill-editor
                    v-model="content"
                    @change="setResponse"
                    style="height: 200px"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import {
  BFormSelect
} from "bootstrap-vue";

export default {
    components: {
        quillEditor,
        BFormSelect
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            content: "",
        }
    },
    methods: {
        setResponse() {
            this.$store.dispatch("toolkitStoreModule/setCurrentWorksheetResponse", this.content);
        }
    },
    mounted() {
        // Reset current worksheet response
        this.$store.dispatch("toolkitStoreModule/resetCurrentWorksheetResponse");

        // Change next button status to enabled
        this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", false);
    }
}
</script>

<style scoped>
    .w-80 {
        max-width: 80%;
    }

    .w-500 {
        max-width: 500px;
    }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
