<template>
  <div>
    <div class="text-center">
        <div class="mt-3" v-if="data.text_2">
            <p class="font-weight-normal">{{ data.text_2 }}</p>
        </div>
        <div class="my-3 font-size-16">
            <a :href="data.worksheet_content.document_link" target="_blank" @click="linkClicked">
              <feather-icon icon="FileIcon" size="22" class="mr-05"/> 
              View document
            </a>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";

export default {
  components: {
    BFormTextarea,
  },
  props: {
    data: {
        type: Object,
        required: true
    }
  },
  methods: {
    linkClicked() {
        this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", false);
    }
  },
};
</script>
