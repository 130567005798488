<template>
    <b-modal
      id="help-modal"
      ref="help-modal"
      hide-footer
      centered
      size="lg"
    >
      <template #modal-title> {{ title }} - Help </template>
      <div class="d-block py-2 px-1">
        <div class="w-100 color-gray">
            <div v-html="content"></div>
        </div>
      </div>
    </b-modal>
</template>

<script>
import EventBus from "../../../main.js"
import { BLink, BModal, BButton, BTable } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BModal,
    BButton,
    BTable,
  },
  props: {
      title: {
        type: String,
      },
      content: {}
  },
  methods: {
    openModal() {
      if(this.$refs['help-modal'] != undefined) {
        // Show modal
        this.$refs["help-modal"].show();
      }
    },
  },
  mounted() {
      EventBus.$on('openHelpToolkitModal', () => {
        this.openModal()
      })
  }
};
</script>

<style>
p {
  font-weight: normal;
}
</style>