<template>
  <div>
    <div class="text-center">
      <div class="w-80 mt-5 mx-auto">
        <validation-observer ref="newLearnandDevelopToolkit">
          <validation-provider #default="{ errors }" name=" " rules="required">
            <b-form-textarea
              v-model="content"
              @change="setResponse"
              placeholder="Your answer"
              rows="10"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "../../../main.js"
import { BFormTextarea } from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      content: "",
      required,
    };
  },
  watch: {
    content(newContent) {
      if (newContent == "") {
        this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", true);
      } else {
        this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", false);
      }
    },
  },
  mounted() {
    // Reset current worksheet response
    this.$store.dispatch("toolkitStoreModule/resetCurrentWorksheetResponse");

    // Event to reset field
    EventBus.$on('resetTextBoxField', () => {
      // Reset textbox field
      this.content = ""
    });

    // Change next button status to enabled
    // this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", false);
  },
  methods: {
    setResponse() {
      this.$store.dispatch(
        "toolkitStoreModule/setCurrentWorksheetResponse",
        this.content
      );
    },
  },
};
</script>

<style scoped>
.w-80 {
  max-width: 80%;
}
</style>
