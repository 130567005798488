<template>
  <div class="p-2">
    <div>
      <div class="text-center">
        <h3>Well done for completing {{ title }}</h3>
      </div>
      <div class="mt-3 w-600">
        <p class="font-weight-normal text-center">Summary</p>
        <app-collapse accordion type="margin">
          <app-collapse-item
            v-for="(result, index) in summary.results"
            :key="index"
            :title="result.worksheet.text_1"
            :worksheet="result.worksheet"
          >
            <div v-if="result.worksheet.text_2" class="mb-2">
              <small>{{ result.worksheet.text_2 }}</small>
            </div>
            <div v-if="result.result_content != null">
              <div v-if="result.result_content.content">
                <small>Response:</small>
              </div>
              <div v-html="result.result_content.content"></div>
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
    </div>
    <div class="mt-5 text-center">
      <div class="w-500 mx-auto mt-3">
        <label>Reminder</label>
        <b-form-select @change="reminderSet($event)" v-model="selectedReminder" :options="reminderOptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/main.js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BButton, BFormSelect } from "bootstrap-vue";

export default {
  props: {
    title: {
      type: String,
      default: "toolkit",
    },
  },
  components: {
    BButton,
    BFormSelect,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      summary: {},
      selectedReminder: "weekly",
      reminderOptions: [
        { value: "weekly", text: "Weekly"},
        { value: "monthly", text: "Monthly"},
        { value: "quarterly", text: "Quarterly"},
        { value: "never", text: "Never"},
      ]
    };
  },
  computed: {
    currentToolkit() {
      return this.$store.getters["toolkitStoreModule/currentToolkit"];
    },
  },
  methods: {
    inviteFriends() {
      EventBus.$emit("inviteFriends");
    },
    reminderSet() {
      EventBus.$emit("toolkitReminderSet", this.selectedReminder);
    },
    getSubmissionResults() {
      this.$http
        .get("/api/auth/toolkit/submissions/results/" + this.currentToolkit.id)
        .then((res) => {
          if(res != undefined) {
            this.summary = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Something went wrong with your profile. Please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  mounted() {
    this.getSubmissionResults();
    this.reminderSet();
  },
};
</script>

<style scoped>
.w-600 {
  max-width: 600px;
  margin: auto;
}
</style>