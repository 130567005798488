<template>
    <div>
        <div>
            <video ref="video" width="100%" controls class="video-player" controlsList="nodownload" @ended="videoEnded" :key="videoKey">
                <source :src="url" type="video/mp4">
                <source :src="url" type="video/ogg">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            videoKey: 0
        }
    },
    watch: {
        $props: {
            handler(val) {
                this.videoKey++;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        videoEnded() {
            // Set next button disable to false
            this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", false);

            let video = this.$refs.video;
            video.currentTime = 0;
        }
    },
}
</script>

<style>
    .video-player {
        width: 600px;
        max-width: 100%!important;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
</style>