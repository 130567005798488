<template>
    <div>
        <b-card v-if="currentToolkit.enrollment != undefined">
            <div v-if="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex] != undefined">
                <b-row class="justify-content-center">
                    <h4>{{currentToolkit.enrollment.toolkit.title}}</h4>
                </b-row>

                <b-row class="no-gutters mt-05" v-if="currentToolkit.enrollment.toolkit.professional != null">
                    <div class="d-flex justify-center items-center mx-auto cursor-pointer" @click="openProfessionalProfileModal()">
                        <img src="/verified.png" class="mr-05" /> 
                        <small class="text-gray">Reviewed by {{currentToolkit.enrollment.toolkit.professional.first_name}} {{currentToolkit.enrollment.toolkit.professional.last_name}}</small>
                    </div>
                    
                    <professional-profile :professional="currentToolkit.enrollment.toolkit.professional" />
                </b-row>

                <b-row
                    class="justify-content-between no-gutters m-auto mb-2"
                    style="width: 90%"
                >
                    <div class="w-100 mt-2">
                        <b-progress
                            :value="toolkitProgress"
                            max="100"
                            variant="mhc-dark"
                            height=".5rem"
                            width="100%"
                            class="questionnaire-progress"
                        />
                        <div class="text-center mt-1">
                            <small>{{ worksheetIndex }}/{{ currentToolkit.enrollment.toolkit.worksheets.length }}</small>
                        </div>
                    </div>
                </b-row>

                <div class="p-2" v-if="currentToolkit.enrollment.toolkit.worksheets.length > 0">
                    <!-- General -->
                    <div class="text-center">
                        <h3>{{ currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].text_1 }}</h3>
                        <div v-if="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].help">
                            <small class="cursor-pointer" @click="openHelpModal" v-b-tooltip.hover.bottom="'Click to see help'">Help</small>
                        </div>
                    </div>

                    <!-- Worksheets -->
                    <div v-if="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].type.name == 'Audio'">
                        <audio-worksheet :data="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-else-if="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].type.name == 'Video'">
                        <video-worksheet :data="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-else-if="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].type.name == 'Cue card'">
                        <cue-worksheet :data="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-else-if="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].type.name == 'Textbox'">
                        <textbox-worksheet :data="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-else-if="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].type.name == 'Document'">
                        <document-worksheet :data="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex]" />
                    </div>
                </div>

                <help-modal :title="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].text_1" :content="currentToolkit.enrollment.toolkit.worksheets[worksheetIndex].help"/>

                <div class="d-flex ">
                    <b-button class="bg-mhc-dark ml-auto" @click="nextWorksheet" :disabled="nextBtnStatus">
                        Continue
                    </b-button>
                </div>
            </div>
            <div v-else>
                <div>
                    <toolkit-completed :title="currentToolkit.enrollment.toolkit.title"/>
                    <div class="d-flex ">
                        <b-button class="bg-mhc-dark ml-auto" @click="finishWorksheet">
                            Finish
                        </b-button>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import EventBus from "@/main.js"
import DocumentWorksheet from '@/components/toolkit/worksheets/DocumentWorksheet.vue'
import AudioWorksheet from '@/components/toolkit/worksheets/audio/AudioWorksheet.vue'
import VideoWorksheet from '@/components/toolkit/worksheets/video/VideoWorksheet.vue'
import CueWorksheet from '@/components/toolkit/worksheets/CueWorksheet.vue'
import TextboxWorksheet from '@/components/toolkit/worksheets/TextboxWorksheet.vue'
import ToolkitCompleted from '@/components/toolkit/worksheets/ToolkitCompleted.vue'
import HelpModal from "@/components/modals/toolkit/HelpModal.vue"
import ProfessionalProfile from "@/components/modals/ProfessionalProfile.vue"
import {
  BCard, BRow, BProgress, BButton, VBTooltip
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BRow,
        BProgress,
        BButton,
        DocumentWorksheet,
        AudioWorksheet,
        VideoWorksheet,
        CueWorksheet,
        TextboxWorksheet,
        ToolkitCompleted,
        HelpModal,
        ProfessionalProfile
    },
    directives: { 'b-tooltip': VBTooltip },
    computed: {
        currentToolkit() {
            return this.$store.getters["toolkitStoreModule/currentToolkit"]
        },
        toolkitProgress() {
            return this.$store.getters["toolkitStoreModule/toolkitProgress"]
        },
        worksheetIndex() {
            return this.$store.getters["toolkitStoreModule/worksheetIndex"]
        },
        nextBtnStatus() {
            return this.$store.getters["toolkitStoreModule/nextBtnStatus"]
        },
    },
    data() {
        return {
            reminderFrequency: ""
        }
    },
    methods: {
        nextWorksheet() {
            let result = {
                "worksheet": {
                    "id": this.currentToolkit.enrollment.toolkit.worksheets[this.worksheetIndex].id,
                    "type": {
                        "name": this.currentToolkit.enrollment.toolkit.worksheets[this.worksheetIndex].type.name
                    }
                },
                "submission": this.currentToolkit.id,
                "response": this.currentToolkit.enrollment.toolkit.worksheets[this.worksheetIndex].response
            }

            this.$http.post("/api/auth/toolkit/results", result)
            .then((res) => {
                // Increment worksheet index (open new worksheet)
                this.$store.dispatch("toolkitStoreModule/incrementWorksheetIndex");

                // Change next button to disabled
                this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", true);

                // Update toolkit progress
                this.$store.dispatch("toolkitStoreModule/setToolkitProgress", (this.worksheetIndex / this.currentToolkit.enrollment.toolkit.worksheets.length) * 100);

                // Reset textbox worksheet field
                EventBus.$emit('resetTextBoxField')
            })
            .catch((err) => {
                let errorMsg = "Something went wrong. Please login again"
                if(err.response.data.message != null) {
                    errorMsg = err.response.data.message
                }

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorMsg,
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })

        },
        finishWorksheet() {
            // Request to finish endpoint
            let toolkitSubmissionId = {
                "submission": {
                    "id": this.currentToolkit.id
                },
                "reminder_frequency": this.reminderFrequency
            }

            this.$http.patch("/api/auth/toolkit/enrollment/finish", toolkitSubmissionId)
            .then((res) => {
                // Reset worksheet index
                this.$store.dispatch("toolkitStoreModule/resetWorksheetIndex");
                
                // Redirect to all toolkits
                this.$router.push({name: 'learn-and-develop'})

                // Success toast
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Toolkit completed!",
                        icon: "CheckSquareIcon",
                        variant: "success",
                    },
                });
            })
            .catch((err) => {
                console.log(err);

                let errorMsg = "Something went wrong with finishing toolkit. Please try again later"
                if(err.response.data.message != null) {
                    errorMsg = err.response.data.message
                }

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorMsg,
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })

        },
        openHelpModal() {
            EventBus.$emit('openHelpToolkitModal')
        },
        openProfessionalProfileModal() {
          EventBus.$emit('openProfessionalProfileModal');
        },
    },
    mounted() {
        // if user accessed this page (toolkit) using the provided buttons on the app
        // then allow the user to load everything and do the toolkit
        if(this.$route.params.usingApp != undefined) {
            // Set current toolkit
            this.$store.dispatch("toolkitStoreModule/setCurrentToolkit", this.$route.params.slug)
        } else {
            // if the user accessed this page directly via link
            // if this is the case then we have to check if all conditions are met to display the toolkit to the user
            // call the api that does all the checks
            // if it returns true, then do it.. else, redirect the user to the learn and develop page
            this.$http.get("/api/auth/toolkit/status/" + this.$route.params.slug)
            .then((res) => {
                this.$store.dispatch("toolkitStoreModule/setCurrentToolkit", this.$route.params.slug)
            })
            .catch((err) => {  
                this.$router.push("/learn-and-develop")
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: err.response.data.message,
                        icon: "AlertCircleIcon",
                        variant: "info",
                    },
                }, 
                {
                    timeout: 10000,
                });
            })
        }
        
        // Event for getting fresh reactions for Finished trait support modal
        EventBus.$on('toolkitReminderSet', (reminderFrequency) => {
            this.reminderFrequency = reminderFrequency;
        });
    }
}
</script>

<style scoped>
button.bg-mhc-dark.disabled{
    background-color: #eaecee !important;
    border-color: #eaecee !important;
}
button.bg-mhc-dark.disabled:hover{
    background-color: #eaecee !important;
    border-color: #eaecee !important;
}
</style>